import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Row } from '@devstart/react-bootstrap';
import { unlockUserAccount } from '../redux/actions';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import { ButtonTypes } from '../components/ui/Button/button-types';
import { Spacer } from '../components/helpers';
import BackButton from '../components/ui/BackButton';
import InputPassword from '../components/ui/input-password';

import './forgot-password.css';

type FormValues = {
  cpf: string;
  password: string;
};

type UnlockAccountProps = {
  readonly unlockUserAccount: (formValues: FormValues) => void;
};

const mapDispatchToProps = {
  unlockUserAccount
};

const defaultValues = {
  cpf: '',
  password: ''
};

function UnlockAccount({ unlockUserAccount }: UnlockAccountProps) {
  const [formValues, setFormValues] = useState<FormValues>(defaultValues);
  const [validationForm, setValidationForm] =
    useState<FormValues>(defaultValues);

  function handleCpfChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, cpf: '' });
    return setFormValues({ ...formValues, cpf: value });
  }

  function handlePasswordChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, password: '' });
    return setFormValues({ ...formValues, password: value });
  }

  function isEmptyField(obj: FormValues) {
    if (obj.cpf === '') {
      setValidationForm({
        ...validationForm,
        cpf: 'Campo está vazio, favor verificar.'
      });
      return true;
    } else if (obj.password === '') {
      setValidationForm({
        ...validationForm,
        password: 'Campo está vazio, favor verificar.'
      });
      return true;
    } else {
      return false;
    }
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const isEmpty = isEmptyField(formValues);
    if (!isEmpty) {
      unlockUserAccount(formValues);
    }
  }

  return (
    <>
      <Helmet title={`Desbloquear conta | DEVstart`} />

      <Grid>
        <Spacer size='small' />

        <Row>
          <BackButton prevUrl='/' />
        </Row>
        <Spacer size='medium' />

        <div className='forgot-password-container'>
          <div className='form-forgot-password-container'>
            <p id='forgot-password-title'>Desbloquear conta</p>
            <Spacer size='small' />

            <p id='forgot-password-subtitle'>
              Sua conta está temporariamente bloqueada. Por motivos de
              segurança, sua conta foi bloqueada devido à inatividade. Para
              desbloqueá-la, por favor confirme seu CPF e Senha
            </p>
            <Spacer size='medium' />

            <form onSubmit={handleSubmit}>
              <Input
                name='cpf'
                hasMask={true}
                maskValue='999.999.999-99'
                placeholder='CPF'
                value={formValues.cpf}
                onChange={handleCpfChange}
                hasError={!!validationForm.cpf}
                messageError={validationForm.cpf}
              />

              <InputPassword
                name='password'
                placeholder='Senha'
                value={formValues.password}
                onChange={handlePasswordChange}
                hasError={!!validationForm.password}
                messageError={validationForm.password}
              />
              <Spacer size='medium' />

              <div className='form-btn'>
                <Button
                  type='submit'
                  buttonType={ButtonTypes.Primary}
                  style={{ height: '48px', width: '100%' }}
                >
                  DESBLOQUEAR CONTA
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Grid>
    </>
  );
}

UnlockAccount.displayName = 'UnlockAccountPage';

export default connect(null, mapDispatchToProps)(UnlockAccount);
